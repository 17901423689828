"use strict"
import { isBrowser } from "./utility"
import ProcessRequest from "./axios.service"
import GGFX_NAMES from "../../../../static/ggfx_servers"

const GATSBY_STRAPI_SRC = process.env.GATSBY_STRAPI_SRC,
  GATSBY_STRAPI_API_AUTH_TOKEN = process.env.GATSBY_STRAPI_FORM_TOKEN;

const PerformAction = (url, imageParams, additionalParams = {}) => {
  if (!imageParams.format) {
    //Default image format jpg to source image format
    imageParams.format = isBrowser() ? (localStorage.getItem("GGFX-NOTWEBP") ? "" : "webp") : ""
  }
  const GgfxHost = additionalParams.ggfxServer;
  console.log('Perform', GGFX_NAMES, GgfxHost, GGFX_NAMES[`GATSBY_STRAPI_${GgfxHost}_SRC`]);
  return ProcessRequest({
    method: additionalParams.method || "get",
    //Strapi url for the application from env
    baseURL: GGFX_NAMES[`GATSBY_STRAPI_${GgfxHost}_SRC`] || GATSBY_STRAPI_SRC || "STRAPI_URL",
    url: `/ggfxservercore/${url}`,
    params: imageParams,
    headers: {
      Authorization: `Bearer ${GGFX_NAMES[`GATSBY_STRAPI_${GgfxHost}_AUTH_TOKEN`] || GATSBY_STRAPI_API_AUTH_TOKEN}`
    }
  })
}

export const GetUrlSet = (imageParams, additionalParams = {}) => {
  return PerformAction("urlset", imageParams, additionalParams)
}
